import { withAuthInfo } from '@propelauth/react'
import { Box, Stack, Typography, Slider, TextField, Autocomplete, Button, FormControlLabel, Checkbox, createFilterOptions } from "@mui/material";
import { makeStyles } from "@mui/styles"
import { Healing, LockOutlined, LockOpen, WorkspacePremium } from '@mui/icons-material';
import { useEffect, useState } from 'react';

const stateCodeToName = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming"
};

const useStyles = makeStyles({ popper: { width: "298px !important", left: "7px !important" } })

const Filters = withAuthInfo((props) => {
  const { displayData, searchFilter, setFilteredColleges } = props

  const [inStateRangeController, setInStateRangeController] = useState([1, 100])
  const [OOStateRangeController, setOOStateRangeController] = useState([1, 100])
  const [meritRangeController, setMeritRangeController] = useState([1, 100])
  const [enrollmentRangeController, setEnrollmentRangeController] = useState([0, 100])
  const [gpaController, setGpaController] = useState("")
  const [satController, setSatController] = useState("")
  const [actController, setActController] = useState("")

  const [quickSearchState, setQuickSearchState] = useState("")
  const [tuitionBudgetController, setTuitionBudgetController] = useState("")
  const [tuitionBudget, setTuitionBudget] = useState(undefined)
  const [expectedMeritRangeController, setExpectedMeritRangeController] = useState(100)
  const [expectedMeritRange, setExpectedMeritRange] = useState(100)


  const [inStateRange, setInStateRange] = useState([1, 100])
  const [OOStateRange, setOOStateRange] = useState([1, 100])
  const [meritRange, setMeritRange] = useState([1, 100])
  const [mustHaveMerit, setMustHaveMerit] = useState(false)
  const [onlyOOSMerit, setOnlyOOSMerit] = useState(false)
  const [filteredStates, setFilteredStates] = useState([])
  const [religiousToggle, setReligiousToggle] = useState(false)
  const [secularToggle, setSecularToggle] = useState(false)
  const [publicToggle, setPublicToggle] = useState(false)
  const [privateToggle, setPrivateToggle] = useState(false)
  const [enrollmentRange, setEnrollmentRange] = useState([0, 100])
  const [highSelectivityToggle, setHighSelectivityToggle] = useState(false)
  const [mediumSelectivityToggle, setMediumSelectivityToggle] = useState(false)
  const [lowSelectivityToggle, setLowSelectivityToggle] = useState(false)
  const [gpa, setGpa] = useState(undefined)
  const [sat, setSat] = useState(undefined)
  const [act, setAct] = useState(undefined)
  const [onlyKnownGrades, setOnlyKnownGrades] = useState(false)

  const privateCard = () => (
    <Stack sx={{ border: "1px solid #63a0b0", color: (privateToggle ? "black" : "#63a0b0"), borderRadius: 3, p: .5, px: 1, width: "fit-content", background: (privateToggle ? "#63a0b0" : undefined) }} direction="row" spacing={1}>
      <LockOutlined />
      <Typography>Private</Typography>
    </Stack>
  )

  const publicCard = () => (
    <Stack sx={{ border: "1px solid #A5C9CA", color: (publicToggle ? "black" : "#A5C9CA"), borderRadius: 3, p: .5, px: 1, width: "fit-content", background: (publicToggle ? "#A5C9CA" : undefined) }} direction="row" spacing={1}>
      <LockOpen />
      <Typography>Public</Typography>
    </Stack>
  )

  const religionCard = () => (
    <Stack sx={{ border: "1px solid #CDBBA7", color: (religiousToggle ? "black" : "#CDBBA7"), borderRadius: 3, p: .5, px: 1, width: "fit-content", background: (religiousToggle ? "#CDBBA7" : undefined) }} direction="row" spacing={1}>
      <Typography>Religious</Typography>
    </Stack>
  )

  const secularCard = () => (
    <Stack sx={{ border: "1px solid #c78b2e", color: (secularToggle ? "black" : "#c78b2e"), borderRadius: 3, p: .5, px: 1, width: "fit-content", background: (secularToggle ? "#c78b2e" : undefined) }} direction="row" spacing={1}>
      <Typography>Secular</Typography>
    </Stack>
  )

  const selectivityCard = (selectivity) => {
    if (selectivity === "high") {
      return (
        <Stack sx={{ border: "1px solid #D9BE4C", color: (highSelectivityToggle ? "black" : "#D9BE4C"), borderRadius: 3, p: .5, px: 1, width: "fit-content", background: (highSelectivityToggle ? "#D9BE4C" : undefined) }} direction="row" spacing={.5}>
          <WorkspacePremium />
          <Typography>High</Typography>
        </Stack>
      )
    } else if (selectivity === "moderate") {
      return (
        <Stack sx={{ border: "1px solid #B3B4BA", color: (mediumSelectivityToggle ? "black" : "#B3B4BA"), borderRadius: 3, p: .5, px: 1, width: "fit-content", background: (mediumSelectivityToggle ? "#B3B4BA" : undefined) }} direction="row" spacing={.5}>
          <WorkspacePremium />
          <Typography>Medium</Typography>
        </Stack>
      )
    } else {
      return (
        <Stack sx={{ border: "1px solid #C07A50", color: (lowSelectivityToggle ? "black" : "#C07A50"), borderRadius: 3, p: .5, px: 1, width: "fit-content", background: (lowSelectivityToggle ? "#C07A50" : undefined) }} direction="row" spacing={.5}>
          <WorkspacePremium />
          <Typography>Low</Typography>
        </Stack>
      )
    }
  }

  useEffect(() => {
    let filteredData = displayData
    filteredData = filteredData.filter((college) => college.Institution.toLowerCase().includes(searchFilter.toLowerCase()))

    filteredData = filteredData.filter((college) => {
      if (tuitionBudget === undefined) {
        return true
      }

      let coa = 0
      if (college["Public or Private"] === "public" && college.State === quickSearchState) {
        coa = parseInt(college["Estimated cost of attendance in-state/year"].replaceAll(",", ""))
      } else if (college["Public or Private"] === "private") {
        coa = parseInt(college["Estimated cost of attendance in-state/year"].replaceAll(",", ""))
      } else {
        coa = parseInt(college["Estimated cost of attendance out-of-state/year"].replaceAll(",", ""))
      }

      coa = coa - ((parseInt(college["Merit High"].replaceAll(",", "")) ?? 0) * (expectedMeritRange / 100))

      return coa <= tuitionBudget
    })

    filteredData = filteredData.filter((college) => {
      if (college["Public or Private"] === "private") {
        return true
      }

      const coa = parseInt(college["Estimated cost of attendance in-state/year"].replaceAll(",", ""))
      if (inStateRange[1] === 100 && coa >= inStateRange[0] * 1000) {
        return true
      } else if (coa <= inStateRange[1] * 1000 && coa >= inStateRange[0] * 1000) {
        return true
      } else {
        return false
      }
    })

    filteredData = filteredData.filter((college) => {
      let coa = 0
      if (college["Public or Private"] === "private") {
        coa = parseInt(college["Estimated cost of attendance in-state/year"].replaceAll(",", ""))
      } else {
        coa = parseInt(college["Estimated cost of attendance out-of-state/year"].replaceAll(",", ""))
      }

      if (OOStateRange[1] === 100 && coa >= OOStateRange[0] * 1000) {
        return true
      } else if (coa <= OOStateRange[1] * 1000 && coa >= OOStateRange[0] * 1000) {
        return true
      } else {
        return false
      }
    })
    filteredData = filteredData.filter((college) => {
      if (college["Merit Low"] === "") {
        if (mustHaveMerit) {
          return false
        } else {
          return true
        }
      }

      const rangeStart = parseInt(college["Merit Low"].replaceAll(",", ""))
      const rangeEnd = parseInt(college["Merit High"].replaceAll(",", ""))

      if (meritRange[1] === 100 && rangeEnd >= meritRange[0] * 1000) {
        return true
      } else if (rangeStart <= meritRange[1] * 1000 &&
        rangeEnd >= meritRange[0] * 1000) {
        return true
      } else {
        return false
      }
    })

    if (onlyOOSMerit) {
      filteredData = filteredData.filter((college) => college["Affordability"] === "out-of-state-merit")
    }

    if (filteredStates.length > 0) {
      filteredData = filteredData.filter((college) => filteredStates.includes(college.State))
    }

    filteredData = filteredData.filter((college) => {
      if (college["Size"] === "") {
        return true
      }

      const size = parseInt(college["Size"].replaceAll(",", ""))
      if (enrollmentRange[1] === 100 && size >= enrollmentRange[0] * 1000) {
        return true
      } else if (size <= enrollmentRange[1] * 1000 && size >= enrollmentRange[0] * 1000) {
        return true
      } else {
        return false
      }
    })

    if (!((highSelectivityToggle && mediumSelectivityToggle && lowSelectivityToggle) || (!highSelectivityToggle && !mediumSelectivityToggle && !lowSelectivityToggle))) {
      filteredData = filteredData.filter((college) => (college["Selectivity"] === "high" && highSelectivityToggle) || (college["Selectivity"] === "moderate" && mediumSelectivityToggle) || (college["Selectivity"] === "less" && lowSelectivityToggle))
    }

    filteredData = filteredData.filter((college) => {
      if (college["Min GPA"] === "not listed" || gpa === undefined) {
        return true
      }

      const minGpa = parseFloat(college["Min GPA"])

      return gpa >= minGpa
    })
    filteredData = filteredData.filter((college) => {
      if (college["Min SAT"] === "not listed" || sat === undefined) {
        return true
      }

      const minSat = parseInt(college["Min SAT"])

      return sat >= minSat
    })
    filteredData = filteredData.filter((college) => {
      if (college["Min ACT"] === "not listed" || act === undefined) {
        return true
      }

      const minAct = parseInt(college["Min ACT"])

      return act >= minAct
    })
    if (onlyKnownGrades) {
      filteredData = filteredData.filter((college) => college["Min GPA"] !== "not listed" || college["Min SAT"] !== "not listed" || college["Min ACT"] !== "not listed")
    }

    if (publicToggle && !privateToggle) {
      filteredData = filteredData.filter((college) => college["Public or Private"] === "public")
    }
    if (privateToggle && !publicToggle) {
      filteredData = filteredData.filter((college) => college["Public or Private"] !== "public")
    }

    if (secularToggle && !religiousToggle) {
      filteredData = filteredData.filter((college) => college["Religious Affiliation"] === "none")
    }
    if (religiousToggle && !secularToggle) {
      filteredData = filteredData.filter((college) => college["Religious Affiliation"] !== "none")
    }

    setFilteredColleges([...filteredData])
  }, [displayData, searchFilter, setFilteredColleges, mustHaveMerit, OOStateRange, inStateRange, meritRange, filteredStates, publicToggle, privateToggle, secularToggle, religiousToggle, onlyOOSMerit, enrollmentRange, highSelectivityToggle, mediumSelectivityToggle, lowSelectivityToggle, gpa, sat, act, onlyKnownGrades, quickSearchState, expectedMeritRange, tuitionBudget])

  const classes = useStyles()

  return (
    <Stack direction="column" spacing={0} sx={{ height: "100vh", position: "fixed", zIndex: 3 }}>
      <Stack direction="row" spacing={1.25} alignItems="center" sx={{ width: 330, height: 57, px: 1, borderBottom: "1px solid rgba(61, 71, 81, 0.3)", background: "#121212" }}>
        <Healing sx={{ color: "#FF2E63" }} fontSize="large" />
        <Typography sx={{ color: "#FF2E63", fontFamily: "Bebas Neue" }} variant="h5">Direct Admit BSN</Typography>
      </Stack>
      <Box sx={{ width: 330, height: "calc(100vh - 57px)", overflowY: "auto", borderRight: "1px solid rgba(61, 71, 81, 0.3)", background: "#121212", zIndex: 3 }}>
        <Stack direction="column" sx={{ overflow: "auto", width: "100%", p: 1, overflowX: "hidden" }}>
          <Box sx={{ width: "95%", height: "0.5px", background: "#FF2E63", mt: 1, mb: 2, position: "relative", left: "50%", transform: "translateX(-50%)" }}>
            <Typography variant='overline' sx={{ position: "relative", left: "20%", top: "-3000%", background: "#121212", width: "fit-content", px: 1, color: "#FF2E63" }}>Personalized Search</Typography>
          </Box>
          <Typography sx={{ mb: 1 }}>Student's Location and Budget</Typography>
          <Stack direction="row" spacing={1}>
            <Autocomplete
              filterOptions={createFilterOptions({
                stringify: (option) => stateCodeToName[option],
              })}
              value={quickSearchState}
              options={Object.keys(stateCodeToName)}
              sx={{ mb: 2, width: "32%" }}
              classes={{ popper: classes.popper }}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <Box
                    key={key}
                    component="li"
                    {...optionProps}
                  >
                    {stateCodeToName[option]}
                  </Box>
                );
              }}
              onChange={(e, newVal) => {
                setQuickSearchState(newVal ?? "")
              }}
              renderInput={(params) => <TextField {...params} label="State" />}
            />
            <TextField label="Max Semester's Tuition" variant="outlined" type="number" sx={{ width: "66%" }} value={tuitionBudgetController} onChange={(e) => {
              setTuitionBudgetController(e.target.value)
            }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  setTuitionBudget(undefined)
                } else {
                  setTuitionBudget(parseInt(e.target.value))
                }
              }} />
          </Stack>
          <Typography sx={{ mt: 1 }}>Expected Merit Award</Typography>
          <Typography variant="body2" sx={{ color: "grey" }}>{expectedMeritRange}% of a school's maximum merit award</Typography>
          <Slider
            min={0}
            max={100}
            value={expectedMeritRangeController}
            sx={{ width: "90%", ml: 1 }}
            onChange={(e, newVal) => {
              setExpectedMeritRangeController(newVal)
            }}
            onChangeCommitted={(e, newVal) => {
              setExpectedMeritRange(newVal)
            }}
            valueLabelDisplay="auto"
          />
          <Typography sx={{ mb: 1 }}>Student's Grades</Typography>
          <Stack direction="row" spacing={1}>
            <TextField label="GPA (4.0)" variant="outlined" type="number" sx={{ width: "calc((100% / 3) - 4px)" }} value={gpaController} onChange={(e) => {
              setGpaController(e.target.value)
            }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  setGpa(undefined)
                } else {
                  setGpa(parseFloat(e.target.value))
                }
              }} />
            <TextField label="ACT" variant="outlined" type="number" sx={{ width: "calc((100% / 3) - 4px)" }} value={actController} onChange={(e) => {
              setActController(e.target.value)
            }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  setAct(undefined)
                } else {
                  setAct(parseInt(e.target.value))
                }
              }} />
            <TextField label="SAT" variant="outlined" type="number" sx={{ width: "calc((100% / 3) - 4px)" }} value={satController} onChange={(e) => {
              setSatController(e.target.value)
            }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  setSat(undefined)
                } else {
                  setSat(parseInt(e.target.value))
                }
              }} />
          </Stack>
          <FormControlLabel sx={{ ml: -1.4, height: 30, mt: .5 }} control={<Checkbox checked={onlyKnownGrades} onChange={() => {
            setOnlyKnownGrades((prev) => !prev)
          }} />} label={<Typography variant='body2' sx={{ color: "grey" }}>Must have known grade value(s)</Typography>} />
          <Box sx={{ width: "95%", height: "0.5px", background: "#FF2E63", mt: 1, mb: 2, position: "relative", left: "50%", transform: "translateX(-50%)" }} />
          <Box>
            <Typography>Out of State Tuition</Typography>
            <Typography variant="body2" sx={{ color: "grey" }}>${OOStateRange[0]}k - ${OOStateRange[1]}k{OOStateRange[1] === 100 ? "+" : ""}</Typography>
            <Slider
              min={1}
              max={100}
              value={OOStateRangeController}
              sx={{ width: "90%", ml: 1 }}
              onChange={(e, newVal) => {
                setOOStateRangeController(newVal)
              }}
              onChangeCommitted={(e, newVal) => {
                setOOStateRange([...newVal])
              }}
              valueLabelDisplay="auto"
            />
          </Box>
          <Box>
            <Typography>In State Tuition <span style={{ color: "grey", fontSize: 14 }}>(Only filters public schools)</span></Typography>
            <Typography variant="body2" sx={{ color: "grey" }}>${inStateRange[0]}k - ${inStateRange[1]}k{inStateRange[1] === 100 ? "+" : ""}</Typography>
            <Slider
              min={1}
              max={100}
              value={inStateRangeController}
              sx={{ width: "90%", ml: 1 }}
              onChange={(e, newVal) => {
                setInStateRangeController(newVal)
              }}
              onChangeCommitted={(e, newVal) => {
                setInStateRange(newVal)
              }}
              valueLabelDisplay="auto"
            />
          </Box>
          <Box>
            <Typography>Estimated Merit Scholarships</Typography>
            <Typography variant="body2" sx={{ color: "grey" }}>${meritRange[0]}k - ${meritRange[1]}k{meritRange[1] === 100 ? "+" : ""}</Typography>
            <Slider
              min={1}
              max={100}
              value={meritRangeController}
              sx={{ width: "90%", ml: 1 }}
              onChange={(e, newVal) => {
                setMeritRangeController(newVal)
              }}
              onChangeCommitted={(e, newVal) => {
                setMeritRange([...newVal])
              }}
              valueLabelDisplay="auto"
            />
            <FormControlLabel sx={{ mt: -2, ml: -1.7, height: 30 }} control={<Checkbox checked={mustHaveMerit} onChange={() => {
              setMustHaveMerit((prev) => !prev)
            }} />} label={<Typography variant='body2' sx={{ color: "grey" }}>Must have merit available</Typography>} />
            <FormControlLabel sx={{ mt: -1, ml: -1.7, height: 30 }} control={<Checkbox checked={onlyOOSMerit} onChange={() => {
              setOnlyOOSMerit((prev) => !prev)
            }} />} label={<Typography variant='body2' sx={{ color: "grey" }}>Must be eligible out of state</Typography>} />
          </Box>
          <Typography sx={{ mb: 1, mt: 1 }}>Location</Typography>
          <Autocomplete
            filterOptions={createFilterOptions({
              stringify: (option) => stateCodeToName[option],
            })}
            multiple
            disableCloseOnSelect
            limitTags={2}
            value={filteredStates}
            options={Object.keys(stateCodeToName)}
            sx={{ mb: 2 }}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <Box
                  key={key}
                  component="li"
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...optionProps}
                >
                  {stateCodeToName[option]}
                </Box>
              );
            }}
            onChange={(e, newVal) => {
              setFilteredStates(newVal ?? [])
            }}
            renderInput={(params) => <TextField {...params} label="State(s)" />}
          />
          <Box>
            <Typography>Total Enrollment</Typography>
            <Typography variant="body2" sx={{ color: "grey" }}>{enrollmentRange[0]}{enrollmentRange[0] === 0 ? "" : ",000"} - {enrollmentRange[1]},000{enrollmentRange[1] === 100 ? "+" : ""}</Typography>
            <Slider
              min={0}
              max={100}
              value={enrollmentRangeController}
              sx={{ width: "90%", ml: 1 }}
              onChange={(e, newVal) => {
                setEnrollmentRangeController(newVal)
              }}
              onChangeCommitted={(e, newVal) => {
                setEnrollmentRange(newVal)
              }}
              valueLabelDisplay="auto"
            />
          </Box>
          <Typography sx={{ mb: .5 }}>Selectivity</Typography>
          <Stack direction="row" spacing={1}>
            <Button onClick={() => {
              setHighSelectivityToggle((prevToggle) => !prevToggle)
            }}>
              {selectivityCard("high")}
            </Button>
            <Button onClick={() => {
              setMediumSelectivityToggle((prevToggle) => !prevToggle)
            }}>
              {selectivityCard("moderate")}
            </Button>
            <Button onClick={() => {
              setLowSelectivityToggle((prevToggle) => !prevToggle)
            }}>
              {selectivityCard("less")}
            </Button>
          </Stack>
          <Typography sx={{ mb: .5 }}>Public or Private</Typography>
          <Stack direction="row">
            <Button onClick={() => {
              setPublicToggle((prevToggle) => !prevToggle)
            }}>
              {publicCard()}
            </Button>
            <Button onClick={() => {
              setPrivateToggle((prevToggle) => !prevToggle)
            }}>
              {privateCard()}
            </Button>
          </Stack>
        </Stack >
        <Typography sx={{ mb: .5, ml: 1 }}>Religious Affiliation</Typography>
        <Stack direction="row" sx={{ ml: 1 }}>
          <Button onClick={() => {
            setReligiousToggle((prevToggle) => !prevToggle)
          }}>
            {religionCard()}
          </Button>
          <Button onClick={() => {
            setSecularToggle((prevToggle) => !prevToggle)
          }}>
            {secularCard()}
          </Button>
        </Stack>
        <Box sx={{ width: "95%", height: "0.5px", background: "grey", mt: 1, position: "relative", left: "50%", transform: "translateX(-50%)" }} />
        <Button sx={{ position: "relative", left: "50%", transform: "translateX(-50%)", my: 2 }} variant="contained" onClick={() => {
          setInStateRangeController([1, 100]);
          setOOStateRangeController([1, 100]);
          setMeritRangeController([1, 100]);
          setEnrollmentRangeController([0, 100]);
          setGpaController("")
          setSatController("")
          setActController("")

          setQuickSearchState("")
          setTuitionBudgetController("")
          setTuitionBudget(undefined)
          setExpectedMeritRangeController(100)
          setExpectedMeritRange(100)

          setInStateRange([1, 100]);
          setOOStateRange([1, 100]);
          setMeritRange([1, 100]);
          setMustHaveMerit(false);
          setOnlyOOSMerit(false);
          setFilteredStates([]);
          setReligiousToggle(false);
          setSecularToggle(false);
          setPublicToggle(false);
          setPrivateToggle(false);
          setEnrollmentRange([0, 100]);
          setHighSelectivityToggle(false);
          setMediumSelectivityToggle(false);
          setLowSelectivityToggle(false);
          setGpa(undefined);
          setSat(undefined);
          setAct(undefined);
          setOnlyKnownGrades(false);
        }}>Reset Filters</Button>
      </Box >
    </Stack >
  );
})

export default Filters;