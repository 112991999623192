import { useEffect, useState } from "react";
import axios from 'axios';
import { withAuthInfo, useRedirectFunctions } from '@propelauth/react'
import CryptoJS from 'crypto-js';
import { Stack, Box, Typography, ButtonBase } from "@mui/material";
import Header from "../../Header";
import Filters from "../../Filters";
import College from "../../College";
import "./maindata.css";

const a = "ApBGbrdCNh7FyaHcm4bXPakXoFbaPRt9"

const MainData = withAuthInfo((props) => {
  const [displayData, setDisplayData] = useState([])
  const [filteredColleges, setFilteredColleges] = useState([])
  const [searchFilter, setSearchFilter] = useState("")

  const {
    redirectToSignupPage,
  } = useRedirectFunctions()

  let instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    timeout: undefined,
    headers: {
      Authorization: `Bearer ${props.accessToken}`
    }
  });

  const getNewData = (loggedOut) => {
    if (loggedOut) {
      instance = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        timeout: undefined,
      });
    } else {
      instance = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        timeout: undefined,
        headers: {
          Authorization: `Bearer ${props.accessToken}`
        }
      });

    }

    instance.get("/getData").then((data) => {
      const items = data.data.split("|");
      const encrypted_data = items[0];
      const iv = items[1]

      // Decode base64 values
      const ivBytes = CryptoJS.enc.Base64.parse(iv);
      const ciphertextBytes = CryptoJS.enc.Base64.parse(encrypted_data);

      // Decrypt data
      const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: ciphertextBytes },
        CryptoJS.enc.Base64.parse(a),
        { iv: ivBytes }
      );

      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
      setDisplayData(JSON.parse(decryptedData));
    }).catch((e) => console.error(e));
  }

  useEffect(() => {
    getNewData()
  }, []);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Stack direction="row" spacing={0}>
        <Filters displayData={displayData} setFilteredColleges={setFilteredColleges} searchFilter={searchFilter} />
        <Box sx={{ width: 330 }} />
        <Box sx={{ width: "calc(100% - 330px)" }}>
          <Header getNewData={getNewData} colleges={filteredColleges} setSearchFilter={setSearchFilter} searchFilter={searchFilter} />
          <Stack direction="column" sx={{ pt: 8, px: 2, zIndex: -1 }} spacing={6}>
            {filteredColleges.map((college) => {
              return <College college={college} />
            })}
            {displayData.length === 10 && (
              <ButtonBase onClick={() => {
                redirectToSignupPage({
                  postLoginRedirectUrl: process.env.REACT_APP_APP_URL + "subscribe",
                  postSignupRedirectUrl: process.env.REACT_APP_APP_URL + "subscribe",
                })
              }} sx={{ background: "linear-gradient(to right, red, purple)", borderRadius: 3, maxWidth: 1000, minWidth: 700 }}>
                <Box sx={{ background: "#121212", width: "100%", m: .3, p: .2, pr: 2, borderRadius: 3 }}>
                  <Typography variant="h4" sx={{ color: "white", zIndex: 0, m: 1 }}>See all <span style={{ color: "#FF2E63" }}>265</span> direct admit nursing colleges with <span style={{ color: "transparent", fontFamily: "Bebas Neue", background: "linear-gradient(to right, #dc0049, purple)", backgroundClip: "text" }}>Premium</span></Typography>
                </Box>
              </ButtonBase >
            )}
            <br />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
})

export default MainData;