import { useEffect } from "react";
import axios from 'axios';
import { withRequiredAuthInfo } from '@propelauth/react'
import { CircularProgress } from "@mui/material";

const Subscription = withRequiredAuthInfo((props) => {
    const authenticatedInstance = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        timeout: undefined,
        headers: {
            Authorization: `Bearer ${props.accessToken}`
        }
    });

    useEffect(() => {
        authenticatedInstance.post("/create-checkout-session").then((data) => {
            if (data.data.url) {
                window.location.href = data.data.url;  // Manually follow the redirect
            }
        }).catch((e) => console.error(e))
    }, []);

    return (
        <CircularProgress sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
    );
})

export default Subscription;