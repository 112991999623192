import { useEffect, useState } from "react";
import axios from 'axios';
import { withAuthInfo, useRedirectFunctions, useLogoutFunction } from '@propelauth/react'
import { Stack, Box, Typography, IconButton, Menu, MenuItem, Autocomplete, TextField, Button } from "@mui/material";
import { WorkspacePremium, AccountCircle } from '@mui/icons-material';

const Header = withAuthInfo((props) => {
  const [userIsCustomer, setUserIsCustomer] = useState(false)
  const [userIsPremium, setUserIsPremium] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);

  const authenticatedInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    timeout: undefined,
    headers: {
      Authorization: `Bearer ${props.accessToken}`
    }
  });

  const {
    redirectToLoginPage,
    redirectToSignupPage,
    redirectToAccountPage,
  } = useRedirectFunctions()
  const logout = useLogoutFunction()

  useEffect(() => {
    authenticatedInstance.get("/getUserStatus").then((data) => {
      setUserIsCustomer(data.data.isCustomer)
      setUserIsPremium(data.data.isPremium)
    }).catch((e) => console.error(e));
  }, []);

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const purchasePremium = () => {
    authenticatedInstance.post("/create-checkout-session").then((data) => {
      if (data.data.url) {
        window.location.href = data.data.url;  // Manually follow the redirect
      }
    }).catch((e) => console.error(e))
  }

  const reviewPurchases = () => {
    authenticatedInstance.post("/create-portal-session").then((data) => {
      if (data.data.url) {
        window.location.href = data.data.url;  // Manually follow the redirect
      }
    }).catch((e) => console.error(e))
  }

  return (
    <Stack justifyContent="space-between" direction="row" sx={{ height: 57, width: "calc(100% - 330px)", minWidth: 390, overflowY: "hidden", background: "rgba(15, 18, 20, 0.6)", position: "fixed", top: 0, backdropFilter: "blur(8px)", px: 1, zIndex: 5, borderBottom: "1px solid rgba(61, 71, 81, 0.3)" }}>
      <Autocomplete
        options={props.colleges.map((college) => college.Institution)}
        size="small"
        freeSolo
        sx={{ maxWidth: 500, width: "100%", borderRadius: 6, mt: 1, ml: 1, mr: 4 }}
        renderInput={(params) => <TextField {...params} sx={{ borderRadius: 6 }} size="small" label="Search" />}
        onChange={(e, newVal) => {
          props.setSearchFilter(newVal ?? "")
        }}
        onBlur={(e) => {
          props.setSearchFilter(e.target.value ?? "")
        }}
      />
      <Stack direction="row" spacing={1.25} alignItems="center" justifyContent="end" sx={{ height: "100%" }}>
        {userIsPremium ? (
          <Box sx={{ background: "linear-gradient(to right, red, purple)", borderRadius: 5 }}>
            <Stack direction="row" spacing={.5} alignItems="center" sx={{ background: "#121212", m: .3, p: .2, pr: 1, borderRadius: 5 }}>
              <svg width={0} height={0}>
                <linearGradient id="linearColors" x1={0} y1={1} x2={1} y2={1}>
                  <stop offset={0} stopColor="red" />
                  <stop offset={1} stopColor="#cc005c" />
                </linearGradient>
              </svg>
              <WorkspacePremium sx={{ fill: "url(#linearColors)" }} fontSize="large" />
              <Typography sx={{ color: "transparent", fontFamily: "Bebas Neue", background: "linear-gradient(to right, #dc0049, purple)", backgroundClip: "text" }} variant="h5">Premium</Typography>
            </Stack>
          </Box>
        ) : (
          <Button sx={{ background: "linear-gradient(to right, red, purple)", borderRadius: 5, p: 0 }} onClick={() => {
            redirectToSignupPage({
              postLoginRedirectUrl: process.env.REACT_APP_APP_URL + "subscribe",
              postSignupRedirectUrl: process.env.REACT_APP_APP_URL + "subscribe",
            })
          }}>
            <Stack direction="row" spacing={.5} alignItems="center" sx={{ background: "#121212", m: .3, p: .2, pr: 1, borderRadius: 5 }}>
              <svg width={0} height={0}>
                <linearGradient id="linearColors" x1={0} y1={1} x2={1} y2={1}>
                  <stop offset={0} stopColor="red" />
                  <stop offset={1} stopColor="#cc005c" />
                </linearGradient>
              </svg>
              <WorkspacePremium sx={{ fill: "url(#linearColors)" }} fontSize="large" />
              <Typography sx={{ color: "transparent", fontFamily: "Bebas Neue", background: "linear-gradient(to right, #dc0049, purple)", backgroundClip: "text", whiteSpace: "nowrap" }} variant="h5">Purchase Premium</Typography>
            </Stack>
          </Button>
        )}
        <IconButton sx={{ mt: -4 }} onClick={(e) => {
          setAnchorEl(e.currentTarget)
        }}>
          <AccountCircle />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={closeMenu}
        >
          {props.isLoggedIn ? (
            <>
              <MenuItem onClick={() => {
                redirectToAccountPage()
                closeMenu()
              }}>Profile</MenuItem>
              {userIsCustomer && (<MenuItem onClick={() => {
                reviewPurchases()
                closeMenu()
              }}>Transaction History</MenuItem>)}
              {!userIsPremium && (<MenuItem onClick={() => {
                purchasePremium()
                closeMenu()
              }}>Premium</MenuItem>)}
              <MenuItem onClick={() => {
                logout()
                props.getNewData(true)
                setUserIsPremium(false)
                setUserIsCustomer(false)
                closeMenu()
              }}>Logout</MenuItem>
            </>
          ) : (
            <>
              <MenuItem onClick={() => {
                redirectToSignupPage()
                closeMenu()
              }}>Sign Up</MenuItem>
              <MenuItem onClick={() => {
                redirectToLoginPage()
                closeMenu()
              }}>Login</MenuItem>
              <MenuItem onClick={() => {
                redirectToSignupPage({
                  postLoginRedirectUrl: process.env.REACT_APP_APP_URL + "subscribe",
                  postSignupRedirectUrl: process.env.REACT_APP_APP_URL + "subscribe",
                })
              }}>Premium</MenuItem>
            </>
          )}
        </Menu>
      </Stack>
    </Stack>
  );
})

export default Header;