import { withAuthInfo } from '@propelauth/react'
import { Stack, Typography, Link, Tooltip } from "@mui/material";
import { LockOutlined, LockOpen, WorkspacePremium, Paid, Savings, InfoOutlined } from '@mui/icons-material';
import "./college.css";

const stateCodeToName = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming"
};

//premium fields are COA and merit
const College = withAuthInfo((props) => {

  const privateCard = () => (
    <Stack sx={{ border: "1px solid #63a0b0", color: "#63a0b0", borderRadius: 3, p: .5, px: 1, width: "fit-content" }} direction="row" spacing={1}>
      <LockOutlined />
      <Typography>Private</Typography>
    </Stack>
  )

  const publicCard = () => (
    <Stack sx={{ border: "1px solid #A5C9CA", color: "#A5C9CA", borderRadius: 3, p: .5, px: 1, width: "fit-content" }} direction="row" spacing={1}>
      <LockOpen />
      <Typography>Public</Typography>
    </Stack>
  )

  const relgiousCard = (religion) => (
    <>
      {religion !== "none" && (
        <Stack sx={{ border: "1px solid #CDBBA7", color: "#CDBBA7", borderRadius: 3, p: .5, px: 1, width: "fit-content" }} direction="row" spacing={1}>
          <Typography>{religion}</Typography>
        </Stack>
      )}
    </>
  )

  const OOSMeritCard = (affordability) => {
    if (affordability === "out-of-state-merit") {
      return (
        <Stack sx={{ border: "1px solid #9DC08B", color: "#9DC08B", borderRadius: 3, p: .5, px: 1, width: "fit-content" }} direction="row" spacing={1}>
          <Paid />
          <Typography>Out of State Merit</Typography>
        </Stack>
      )
    }
    return null
  }

  const meritAmountCard = (meritLow, meritHigh) => (
    <Stack sx={{ border: "1px solid #FCE38A", color: "#FCE38A", borderRadius: 3, p: .5, px: 1, width: "fit-content" }} direction="row" spacing={1}>
      <Savings />
      <Typography>${meritLow} - ${meritHigh} Est. Merit</Typography>
    </Stack>
  )

  const gradeCard = (key, value) => {
    if (value !== "not listed") {
      return (
        <Stack sx={{ border: "1px solid #FF2E63", color: "white", p: .5, px: 1, width: "fit-content" }} direction="row" spacing={1}>
          <Typography>{key} ≥ {value}</Typography>
        </Stack>
      )
    } else {
      return null
    }
  }

  return (
    <Stack sx={{ maxWidth: 1000, minWidth: 700, border: "1px solid #121212", borderRadius: 3, p: 1.5, px: 2, background: "#121212" }} direction="row" justifyContent="space-between">
      <Stack direction="column" sx={{ minWidth: 400 }}>
        <Stack direction="row" alignItems="start" sx={{ position: "relative", mt: -.5 }}>
          <WorkspacePremium fontSize="large" sx={{ color: (props.college["Selectivity"] === "high" ? "#D9BE4C" : (props.college["Selectivity"] === "moderate" ? "#B3B4BA" : "#C07A50")), position: "absolute", transform: "translateX(-5px)", mt: .25 }} />
          <Link variant="h4" sx={{ color: "#FF2E63", whiteSpace: "pre-wrap", ml: -.25, zIndex: 0 }} target="_blank" rel="noreferrer" underline="none" href={props.college["BSN Nursing Program Website"]}>{"    "}{props.college.Institution}</Link>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography variant="h6" sx={{ mb: 3, mt: -.5 }}>{props.college.City}, {stateCodeToName[props.college.State]}</Typography>
          <Typography variant="h6" sx={{ mb: 3, mt: -.5, color: "grey" }}>{props.college.Size} students</Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap", mt: .5, mb: 2 }} useFlexGap>
          {gradeCard("GPA", props.college["Min GPA"])}
          {gradeCard("SAT", props.college["Min SAT"])}
          {gradeCard("ACT", props.college["Min ACT"])}
        </Stack>
        <Stack direction="row" spacing={1} sx={{ maxWidth: 400, flexWrap: "wrap" }} useFlexGap>
          {props.college["Public or Private"] === "public" ? publicCard() : privateCard()}
          {relgiousCard(props.college["Religious Affiliation"])}
          {props.college["Merit Low"] && (meritAmountCard(props.college["Merit Low"], props.college["Merit High"]))}
          {OOSMeritCard(props.college["Affordability"])}
        </Stack>
      </Stack>
      <Stack direction="column" sx={{ width: "fit-content", maxWidth: 450 }} justifyContent="space-between" alignItems="end">
        <Stack direction="column" alignItems="end" sx={{ height: 74 }}>
          <Tooltip title="Out-of-State Tuition" arrow placement="left">
            <Typography variant="h4">${props.college["Public or Private"] === "public" ? props.college["Estimated cost of attendance out-of-state/year"] : props.college["Estimated cost of attendance in-state/year"]}</Typography>
          </Tooltip>
          {props.college["Public or Private"] === "public" && (
            <Tooltip title="In-State Tuition" arrow placement="left">
              <Typography variant="h5" sx={{ mt: -.5, color: "#A5C9CA" }}>${props.college["Estimated cost of attendance in-state/year"]}</Typography>
            </Tooltip>
          )}
        </Stack>
        {props.college["Special notes"] && <Typography variant="body1" sx={{ textAlign: "right" }}>{props.college["Special notes"]}</Typography>}
      </Stack>
    </Stack>
  );
})

export default College;